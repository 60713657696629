import { Box, Heading, Highlight, Text, useToken } from '@chakra-ui/react';
import { AboutComponent } from '@/widgets/AboutComponent';
import { RequestAccessModal } from '@/widgets/RequestAccessModal';
import { headerHeight, metaData } from '@/shared/constants';
import { useGlobalState } from '@/shared/providers';
import { DefaultContainer, Image } from '@/shared/ui';
import { MetaDecorator } from '@/shared/utils';
import { productsInfo } from '../lib/benefitsList';
import generalImageMob from '/public/main-page/general-image-mobile.webp';
import generalImage from '/public/main-page/general-image.webp';

const MAIN_TEXT = 'Automate Your Online Management';
const SECOND_TEXT = `Share your business information and updates - we'll handle your listings, reviews, and landing pages across all platforms, providing actionable insights to help your business grow`;

const MainPage = () => {
  const { isMobile } = useGlobalState();
  const mainImage = isMobile ? generalImageMob : generalImage;
  const padding = isMobile ? 24 : 48;
  const maxContainerWidth = useToken('sizes', 'container.2xl');

  return (
    <>
      <MetaDecorator
        description={metaData.main.description}
        keywords={metaData.main.keywords}
        title={metaData.main.title}
      />
      <DefaultContainer
        alignItems='center'
        containerProps={{
          mt: headerHeight,
        }}
        gap={{ base: 'lg', md: 16 }}
        maxW='container.lg'
        py={{ base: 'lg', md: 32 }}
        textAlign='center'
      >
        <Heading
          as='h1'
          size={{ base: '2xl', md: '4xl' }}
        >
          {MAIN_TEXT}
        </Heading>
        <Text
          color='text.medium'
          fontSize={{ base: 'xl', md: '2xl' }}
          w={{ base: '100%', md: '85%' }}
        >
          {SECOND_TEXT}
        </Text>
        <RequestAccessModal size='lg' />
      </DefaultContainer>
      <DefaultContainer
        alignItems='center'
        containerProps={{
          pb: 'md',
          position: 'relative',
        }}
      >
        <Box
          bg='bg.light'
          h='100%'
          left='0px'
          position='absolute'
          top='55%'
          w='100%'
          zIndex='-1'
        />
        <Image
          alt='Benefit image'
          aspectRatio={mainImage.width / mainImage.height}
          height='auto'
          imgSizes={{
            base: `calc(100% - ${padding * 2})`,
            xl: `calc(${maxContainerWidth} - ${padding * 2})`,
          }}
          isEager
          objectFit='contain'
          priority
          src={mainImage}
          width={{ base: '100%', md: '90%' }}
        />
      </DefaultContainer>

      <DefaultContainer
        alignItems={{ base: 'start', md: 'center' }}
        bg='bg.pure'
        gap={{ base: 20, md: 32 }}
      >
        <Heading
          fontWeight='semibold'
          size={{ base: 'xl', md: '2xl' }}
          textAlign='center'
        >
          <Highlight
            query={['achieve', 'goals']}
            styles={{ color: 'primary.pure' }}
          >
            How we help You achieve Your goals
          </Highlight>
        </Heading>
      </DefaultContainer>

      {productsInfo.map((product, index) => {
        const isOdd = index % 2 !== 0;

        return (
          <AboutComponent
            key={product.heading}
            isOdd={isOdd}
            {...product}
          />
        );
      })}
    </>
  );
};

export default MainPage;
