import { Card, ContainerProps, Flex, Heading, IconProps, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface ICardsBlockItem {
  title: string;
  description: string;
  Icon: FC<IconProps>;
}

interface ICardsBlockProps extends ContainerProps {
  list: ICardsBlockItem[];
}

const CardsBlock: FC<ICardsBlockProps> = ({ list, ...rest }) => (
  <Flex
    flexDir={{ base: 'column', md: 'row' }}
    gap={{ base: 'sm', lg: 'lg' }}
    justifyContent='space-between'
    {...rest}
  >
    {list.map(({ title, Icon, description }) => (
      <Card
        key={title}
        flex={1}
        gap='md'
        p='md'
      >
        <Icon
          boxSize={8}
          color='icon.pure'
        />
        <Heading
          as='h3'
          size='lg'
        >
          {title}
        </Heading>
        <Text
          color='text.medium'
          fontSize='lg'
          fontWeight='medium'
        >
          {description}
        </Text>
      </Card>
    ))}
  </Flex>
);

export default CardsBlock;
