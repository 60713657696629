import { Link } from '@chakra-ui/next-js';
import { Button, ContainerProps, Flex, Heading, Text } from '@chakra-ui/react';
import { StaticImageData } from 'next/image';
import { FC, ReactNode } from 'react';
import { CardsBlock } from '@/widgets/CardsBlock';
import { HeadingAs } from '@/shared/types';
import { DefaultContainer, Image, PointIcon } from '@/shared/ui';
import { ImageProps } from 'src/shared/ui/Image';

interface IBenefit {
  title: string;
  description: string;
}
interface IAboutComponentProps extends ContainerProps {
  smallText?: string;
  heading: string;
  description: string;
  image: StaticImageData;
  buttonText?: string;
  href?: string;
  containerProps?: ContainerProps;
  headingAs?: HeadingAs;
  benefits?: IBenefit[];
  benefitsCards?: any;
  isOdd?: boolean;
  button?: ReactNode;
}

interface IImgProps extends Partial<ImageProps> {
  alt: string;
  image: StaticImageData;
}

const Img: FC<IImgProps> = ({ alt, image, ...rest }) => (
  <Image
    alt={alt}
    aspectRatio={image.width / image.height}
    borderRadius='xl'
    height='auto'
    imgSizes={{
      base: '100%',
      md: '40rem',
    }}
    objectFit='contain'
    overflow='hidden'
    src={image}
    width={{ base: 'full', md: '24rem', lg: '32rem' }}
    {...rest}
  />
);

const AboutComponent: FC<IAboutComponentProps> = ({
  smallText,
  heading,
  description,
  image,
  buttonText,
  containerProps,
  benefits,
  headingAs = 'h2',
  href,
  benefitsCards,
  isOdd = false,
  button,
  ...rest
}) => (
  <DefaultContainer
    alignItems='center'
    bg={isOdd ? 'bg.light' : 'bg.pure'}
    containerProps={containerProps}
    display='grid'
    gap='md'
    gridAutoColumns='1fr'
    gridTemplateAreas={{
      base: `"text"
              "image"
              "cards"`,
      md: isOdd
        ? `"image text"
              "cards cards"`
        : `"text image"
              "cards cards"`,
    }}
    justifyItems='center'
    {...rest}
  >
    <Flex
      flexDir='column'
      gap='md'
      gridArea='text'
      w={{ base: 'full', md: '80%' }}
    >
      {smallText && (
        <Text
          color='text.light'
          fontWeight='semibold'
        >
          {smallText}
        </Text>
      )}
      <Heading
        as={headingAs}
        textAlign={{ base: 'center', md: 'left' }}
      >
        {heading}
      </Heading>

      <Img
        alt={heading}
        display={{ base: 'block', md: 'none' }}
        image={image}
      />
      <Text
        fontSize='lg'
        fontWeight='semibold'
      >
        {description}
      </Text>
      {benefits && (
        <Flex
          flexDir='column'
          gap='sm'
        >
          {benefits.map(({ title, description }) => (
            <Flex
              key={title}
              flexDir='column'
            >
              <Flex
                alignItems='center'
                color='primary.pure'
              >
                <PointIcon boxSize={4} />
                <Heading
                  as='h4'
                  fontSize='md'
                >
                  {title}
                </Heading>
              </Flex>
              <Text
                fontWeight='medium'
                ml='sm'
              >
                {description}
              </Text>
            </Flex>
          ))}
        </Flex>
      )}
      {button && button}
      {buttonText && (
        <Button
          as={Link}
          href={href}
          w={{ base: 'full', md: 'fit-content' }}
        >
          {buttonText}
        </Button>
      )}
    </Flex>

    <Img
      alt={heading}
      display={{ base: 'none', md: 'block' }}
      gridArea='image'
      image={image}
    />

    {benefitsCards && (
      <CardsBlock
        gridArea='cards'
        list={benefitsCards}
        mt='md'
      />
    )}
  </DefaultContainer>
);

export default AboutComponent;
