import { GetStaticPaths, GetStaticProps } from 'next';
import { MainPage } from '@/pages/Main';
import { deviceType } from '@/shared/constants';
import { getPaths, parseSSGParams } from '@/shared/helpers';

export const getStaticProps: GetStaticProps = async (context) => {
  const { device } = parseSSGParams(context.params);

  const isValidPath = Object.values(deviceType).includes(device);
  if (!isValidPath) {
    return {
      notFound: true,
    };
  }

  return {
    props: {},
  };
};

export const getStaticPaths: GetStaticPaths = async (context) => {
  return {
    paths: getPaths(),
    fallback: 'blocking',
  };
};

export default MainPage;
