import { ConsistentIcon, GrowthIcon, UsersIcon } from '@/shared/ui';
import localsImage from '/public/main-page/block-1.webp';
import landingImage from '/public/main-page/landing-ex-right.webp';
import reviewsImage from '/public/main-page/reviewsProduct.webp';
import reviewsImage2 from '/public/main-page/save-time.webp';

export const productsInfo = [
  {
    buttonText: 'See how we boost Your visibility',
    description:
      'Stand out where it counts. We enhance your visibility on Google, Apple Maps, Facebook, and other platforms, ensuring consistent and accurate information so more customers find and choose your business',
    heading: 'Bring in more customers',
    href: '/products/locals',
    image: localsImage,
    benefitsCards: [
      {
        title: 'Increase online visibility',
        description: 'Appear at the top of local search results to attract nearby customers',
        Icon: GrowthIcon,
      },
      {
        title: 'Consistent business information',
        description:
          'Keep your data uniform across all platforms to build trust and avoid customer confusion',
        Icon: ConsistentIcon,
      },
      {
        title: 'Engage more clients',
        description:
          'Create compelling profiles and showcase what makes your business unique to win over more clients',
        Icon: UsersIcon,
      },
    ],
  },
  {
    buttonText: 'Learn about review management',
    description:
      'Encourage satisfied customers to leave positive reviews, enhancing your reputation and attracting more clients',
    heading: 'Build a stellar reputation with more reviews',
    href: '/products/reviews',
    image: reviewsImage,
    benefits: [
      {
        title: 'Automated review requests',
        description:
          'Easily send automated requests to happy customers, increasing positive feedback',
      },
      {
        title: 'Monitor feedback',
        description: 'Stay on top of what customers are saying to continually improve',
      },
      {
        title: 'Showcase success',
        description: 'Display glowing reviews to build trust with potential clients',
      },
      {
        title: 'Strengthen customer relationships',
        description: 'Engage promptly with customers by responding to reviews from a single place',
      },
    ],
  },
  {
    buttonText: 'Discover time-saving tools',
    description:
      'Simplify your online management. Update your business information and respond to reviews from one central place, giving you more time to run your business',
    heading: 'Save time and focus on what matters',
    href: '/products/reviews',
    image: reviewsImage2,
    benefits: [
      {
        title: 'Centralized control',
        description: 'Manage all your listings and reviews across platforms effortlessly',
      },
      {
        title: 'Automated tools',
        description:
          'Use templates and automation to handle tasks quickly and efficiently, including automated review requests',
      },
      {
        title: 'User-friendly interface',
        description: ' No technical expertise required—our platform is designed for ease of use',
      },
      {
        title: 'Time savings',
        description: 'Spend less time on administrative tasks and more on growing your business',
      },
    ],
  },
  {
    buttonText: 'Create your landing page now',
    description:
      'Quickly create professional, hosted landing pages that showcase your reviews, portfolio, and services—without the wait or cost of building a website',
    heading: 'Enhance Your online presence',
    href: '/products/pages',
    image: landingImage,
    benefits: [
      {
        title: 'Instant landing pages',
        description: 'Generate SEO-optimized landing pages for your business in minutes',
      },
      {
        title: 'Leverage live content',
        description:
          'Connect your real-time reviews and up-to-date business information to build trust',
      },
      {
        title: 'Showcase portfolio and services',
        description: 'Highlight what you offer to attract and inform potential customers',
      },
      {
        title: 'No technical delays',
        description:
          'Get your online presence up and running fast, without waiting for web developers',
      },
    ],
  },
];
